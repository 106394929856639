import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductSweetGoods } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import SourdoughFruitScone from '../images/sourdough-fruit-scones.png';
import BananaBread from '../images/banana-bread.png';
import CinnamonStarBread from '../images/cinnamon-star-bread.png';
import AppleBread from '../images/apple-bread.png';
import PumpkinBread from '../images/pumpkin-bread.png';
import FruitBuckle from '../images/fruit-buckle.png';
import MonsterBrownies from '../images/monster-brownies.png';
import AppleSpeltCake from '../images/apple-spelt-cake.png';

function ProductSweetGoods() {
    const enabled = useRecoilValue(viewProductSweetGoods);

    const handleClick = (e, param) => {
        var body = "";
        var subject = "";
        var commonPostFix = " Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours. If ordering multiple items feel free to just include everything you'd like here in one email to us. The best way to reach us is by calling or texting us at (770) 504-6617 with your order or questions. We look forward to working with you!";

        switch(param) {
            case 'sourdoughFruitScone':
                subject = "Bleu Fox Bakery Online Order - Sourdough Fruit Scones";
                body = "Thank you for your interest in the Sourdough Fruit Scones.";
                break;
            case 'bananaBread':
                subject = "Bleu Fox Bakery Online Order - Banana Bread";
                body = "Thank you for your interest in the Banana Bread.";
                break;
            case 'appleSpeltCake':
                subject = "Bleu Fox Bakery Online Order - Apple Spelt Cake";
                body = "Thank you for your interest in the Apple Spelt Cake.";
                break;
            case 'cinnamonStarBread':
                subject = "Bleu Fox Bakery Online Order - Cinnamon Star Bread";
                body = "Thank you for your interest in the Cinnamon Star Bread.";
                break;
            case 'fruitBuckle':
                subject = "Bleu Fox Bakery Online Order - Fruit Buckle";
                body = "Thank you for your interest in the Fruit Buckle.";
                break;
            case 'appleBread':
                subject = "Bleu Fox Bakery Online Order - Apple Cinnamon Bread";
                body = "Thank you for your interest in the Apple Cinnamon Bread.";
                break;
            case 'pumpkinBread':
                subject = "Bleu Fox Bakery Online Order - Pumpkin Bread";
                body = "Thank you for your interest in the Pumpkin Bread.";
                break;
            case 'brownieMinis':
                subject = "Bleu Fox Bakery Online Order - Brownie Minis";
                body = "Thank you for your interest in the Brownie Minis.";
                break;
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body + commonPostFix;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AppleSpeltCake}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Apple Spelt Cake
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Indulge in the warm, nutty flavors of our lightly spiced individual cakes made with spelt flour, perfect for capturing that autumn spirit. They offer just the right amount of sweetness, making them an ideal choice for breakfast with a great cup of coffee, a mid-afternoon boost or even a lovely dessert paired with some chamomile. Our Apple Spelt Cakes are brimming with walnuts, raisins, and honey crisp apples, harmonizing beautifully to create a symphony of flavors in this delightful treat.</p>
                                        <p>Ingredients: Apple, Raisins, Spelt Flour, Flour, Walnuts, Brown Sugar, Sugar, Sunflower Oil, Eggs, Milk, Olive Oil, Baking Soda, Vanilla, Cinnamon and Salt.</p>
                                        <p>Price: $4 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'appleSpeltCake');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BananaBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Banana Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Beautifully ripened bananas give this wonderful banana bread the perfect amount of sweetness you want in a banana bread. Soft and delicious, with a moist interior and a rich banana flavor. These mini loaves will be a hit at your breakfast or brunch table.</p>
                                        <p>Ingredients: Flour, Brown Sugar, Butter, Banana, Egg, Baking Soda and Salt.</p>
                                        <p>Price: $5 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'bananaBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={CinnamonStarBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Cinnamon Star Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Indulge in our delightful Cinnamon Star Bread, a stunning pull-apart sweet bread that makes for an eye-catching centerpiece at any gathering. Each layer is generously filled with a delicious cinnamon-sugar blend, reminiscent of a traditional cinnamon roll. Baked to a perfect golden brown on the outside, it boasts a soft and fluffy interior and then glazed with a light icing. If you happen to have any leftovers the next day, simply warm a piece in the microwave for 20 seconds and enjoy it alongside your favorite coffee for a truly fantastic breakfast experience.</p>
                                        <p>Ingredients: Flour, Whole Milk, Butter, Eggs, Spring Water, Cinnamon, Yeast, Malt Barley and Salt.</p>
                                        <p>Price: $12 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'cinnamonStarBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={SourdoughFruitScone}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Sourdough Fruit Scones
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Indulge in our Sourdough Fruit Scones, which boast a delightful flavor that’s anything but dry or crumbly. These scones are soft and flaky, with just the right hint of sweetness making each bite a pleasure. The sourdough levain enhances the flavor profile without introducing any sour notes, allowing the rich array of tastes to shine through. Ideal for breakfast or brunch, these delectable scones are best enjoyed fresh but can be savored for a day or two—if you can resist finishing them all at once!</p>
                                        <p>Ingredients: Flour, Butter, Sugar, Milk, Dried Fruit, Egg, Turbinado Sugar, Baking Powder and Salt. </p>
                                        <p>Price: $3 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'sourdoughFruitScone');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={FruitBuckle}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Fruit Buckle
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>The Fruit Buckle has an amazing combination of flavors and textures. With a rustic appearance in a tulip cup, you'll be greeted with a soft and moist crumb, mellow sweetness, and seasonal fruit. Lastly, the Fruit Buckles are topped with a delightful streusel that ties everything together.</p>
                                        <p>Ingredients: Flour, Milk, Fruit (specific to order), Sugar, Butter, Brown Sugar, Egg, Cornstarch, Baking Powder, Vanilla, Salt and Lemon Juice.</p>
                                        <p>Price: $4 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'fruitBuckle');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AppleBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Apple Cinnamon Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our mildly sweet Apple Cinnamon Bread boasts a moist texture and a delicate crumb, delighting the palate with the comforting aromas of cinnamon and nutmeg. Topped with cinnamon and brown sugar, it serves as an ideal indulgence for a crisp autumn day.</p>
                                        <p>Ingredients: Applesauce, Flour, White Wheat Flour, Eggs, Sugar, Brown Sugar, Sunflower Oil, Whole Milk, Olive Oil, Baking Soda, Cinnamon, Baking Powder, Salt and Nutmeg.</p>
                                        <p>Price: $5 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'appleBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MonsterBrownies}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Brownie Minis
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>These are everything a brownie should be; a fudgy center, a crackly top, and chewy edges with every bite. These brownies are rich and decadent, and will satisfy your chocolate craving. Made with high quality dark chocolate. Big flavors come in small packages.</p>
                                        <p>Ingredients: Sugar, Flour, Dark Chocolate, Sunflower/Olive Oil Blend, Eggs, Cocoa Powder, Spring Water, Salt and Vanilla.</p>
                                        <p>Price: $5 USD (5 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'brownieMinis');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PumpkinBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Pumpkin Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This delectable sweet bread, infused with pumpkin flavor and warm autumn spices, offers a delightful treat. The mini loaf boasts a uniformly fine and delicate crumb, yielding an exceptionally soft and moist texture. It is perfect for enjoying solo or alongside a soothing cup of tea or coffee.</p>
                                        <p>Ingredients: Pure Pumpkin Puree, Flour, Sugar, Brown Sugar, Butter, Eggs, Canola Oil, Baking Soda, Cinnamon, Cloves, Nutmeg and Salt.</p>
                                        <p>Price: $5 USD (each)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'pumpkinBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductSweetGoods;